import { AuthConfig } from 'angular-oauth2-oidc';

export const environment = {
  production: false,
  baseUrlDiscord: 'https://discord.com/api',
  // baseUrlBot: 'http://134.255.233.178:5001',

  paypalClientId: 'AUNXbKfsmECOFeE9nALy1ddlYSv86QouHYqF7B55FbsmhqV_Z9ye_zTslL9C3cgKhNoWjACXQsHnUqT7',
  paypalCurrency: 'EUR',
};

export const authConfig: AuthConfig = {
  issuer: 'https://discord.com/',
  redirectUri: 'https://beta.raidorganizer.org/',
  clientId: '736116031772164128',
  dummyClientSecret: 'MdfI2sj3NM6lNojgXLhBRpsIr5rsEw-H',
  responseType: 'code',
  scope: 'identify connections guilds',
  showDebugInformation: true,

  oidc: false,
  loginUrl: 'https://discord.com/api/oauth2/authorize',
  tokenEndpoint: 'https://discord.com/api/oauth2/token',
  userinfoEndpoint: 'https://discordapp.com/api/users/@me',
  revocationEndpoint: 'https://discord.com/api/oauth2/token/revoke',
};
