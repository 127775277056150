<ng-container *ngIf="guild">
  <div class="tab-pane fade show pt-5" id="" role="tabpanel" aria-labelledby="paypal-tab">
    <div class="row justify-content-center">
      <div class="col-xl-8 col-lg-12">
        <nav>
          <div class="nav nav-tabs nav-justified" id="nav-paypal-tabs" role="tablist" style="border:0px">
            <button class="nav-link bg-discord rounded-top-left-05 active" id="nav-premium-tab" data-toggle="tab"
              data-target="#nav-premium" type="button" role="tab" aria-controls="nav-premium"
              aria-selected="true">Premium</button>
            <button class="nav-link bg-paypal rounded-top-right-05" id="nav-limits-tab" data-toggle="tab"
              data-target="#nav-limits" type="button" role="tab" aria-controls="nav-limits"
              aria-selected="false">Limits</button>
          </div>
        </nav>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-xl-8 col-lg-12">
        <div class="tab-content" id="nav-paypal-tabs">
          <div class="tab-pane fade show active row" id="nav-premium" role="tabpanel" aria-labelledby="nav-premium-tab">
            <div class="card-body d-flex flex-column tab-content" id="nav-tabContent">
              <p>You can check your Premium status with <code>/premium</code></p>
              <b>Notes:</b>
              <ul>
                <li>The payments below are no subscription! If you want to subscribe, please use <a
                    href="https://www.patreon.com/raid_organizer" target="_blank">Patreon</a>.</li>
                <li>Payments via PayPal normally take ~10 minutes to confirm. Via Debit/Credit Card it can up to 1 day.
                </li>
              </ul>
              <nav>
                <div class="nav nav-tabs nav-justified" id="pay-tab" role="tablist">
                  <button class="nav-link rounded-top-left-025" id="nav-1-tab" data-toggle="tab" data-target="#nav-1"
                    type="button" role="tab" aria-controls="nav-1" aria-selected="false">1 Month</button>
                  <button class="nav-link rounded-0 active" id="nav-6-tab" data-toggle="tab" data-target="#nav-6"
                    type="button" role="tab" aria-controls="nav-6" aria-selected="true">6 Months</button>
                  <button class="nav-link rounded-0" id="nav-12-tab" data-toggle="tab" data-target="#nav-12"
                    type="button" role="tab" aria-controls="nav-12" aria-selected="false">12 Months</button>
                  <button class="nav-link rounded-top-right-025" id="nav-lifetime-tab" data-toggle="tab"
                    data-target="#nav-lifetime" type="button" role="tab" aria-controls="nav-lifetime"
                    aria-selected="false">Lifetime</button>
                </div>
              </nav>
              <div class="tab-pane fade" id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab">
                <div class="row justify-content-center">
                  <div class="col-6">
                    <ul>
                      <li>Premium for <b>1 Server</b> and <b>1 Months</b></li>
                      <li>Unlock all <a href="premium/#feature-table" target="_blank">Premium</a> features</li>
                      <li>Get the <i>Donator</i> role on our <a href="https://discord.raidorganizer.org"
                          target="_blank">Discord server</a></li>
                    </ul>
                    <p class="text-center txt-paypal">3.50€</p>
                    <ngx-paypal #paypalBtnPremium1Month [config]="payPalConfigPremium1Month"></ngx-paypal>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade show active" id="nav-6" role="tabpanel" aria-labelledby="nav-6-tab">
                <div class="row justify-content-center">
                  <div class="col-6">
                    <ul>
                      <li>Premium for <b>1 Server</b> and <b>6 Months</b></li>
                      <li>Unlock all <a href="premium/#feature-table" target="_blank">Premium</a> features</li>
                      <li>Get the <i>Donator</i> role on our <a href="https://discord.raidorganizer.org"
                          target="_blank">Discord server</a></li>
                    </ul>
                    <p class="text-center txt-paypal" style="margin-bottom: 0px;">20.00€</p>
                    <i>
                      <p class="txt-discount"> 3.33€ per month</p>
                    </i>
                    <ngx-paypal #paypalBtnPremium6Month [config]="payPalConfigPremium6Month"></ngx-paypal>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="nav-12" role="tabpanel" aria-labelledby="nav-12-tab">
                <div class="row justify-content-center">
                  <div class="col-6">
                    <ul>
                      <li>Premium for <b>1 Server</b> and <b>12 Months</b></li>
                      <li>Unlock all <a href="premium/#feature-table" target="_blank">Premium</a> features</li>
                      <li>Get the <i>Donator</i> role on our <a href="https://discord.raidorganizer.org"
                          target="_blank">Discord server</a></li>
                    </ul>
                    <p class="text-center txt-paypal" style="margin-bottom: 0px;">38.00€</p>
                    <i>
                      <p class="txt-discount"> 3.16€ per month</p>
                    </i>
                    <ngx-paypal #paypalBtnPremium12Month [config]="payPalConfigPremium12Month"></ngx-paypal>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="nav-lifetime" role="tabpanel" aria-labelledby="nav-lifetime-tab">
                <div class="row justify-content-center">
                  <div class="col-6">
                    <ul>
                      <li>Premium for <b>1 Server</b> and <b>Lifetime Months</b></li>
                      <li>Unlock all <a href="premium/#feature-table" target="_blank">Premium</a> features</li>
                      <li>Get the <i>Donator</i> role on our <a href="https://discord.raidorganizer.org"
                          target="_blank">Discord server</a></li>
                    </ul>
                    <p class="text-center txt-paypal" style="margin-bottom: 0px;">80.00€</p>
                    <i>
                      <p class="txt-discount"> Free after 23 months</p>
                    </i>
                    <ngx-paypal #paypalBtnPremiumLifetime [config]="payPalConfigPremiumLifetime"></ngx-paypal>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade row" id="nav-limits" role="tabpanel" aria-labelledby="nav-limits-tab">
            <div class="card-body d-flex flex-column tab-content" id="nav-tabContent">
              <p>You can check your current limits with <code>/limits</code></p>
              <b>Notes:</b>
              <ul>
                <li>Limit increases are forever!</li>
                <li>Payments via PayPal normally take ~10 minutes to confirm. Via Debit/Credit Card it can up to 1 day.
                </li>
              </ul>
              <div id="accordion">
                <div class="card">
                  <div class="card-header" id="headingOne">
                    <h5 class="mb-0">
                      <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
                        aria-expanded="true" aria-controls="collapseOne">
                        +5 Event Channels (5 EUR)
                      </button>
                    </h5>
                  </div>

                  <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                      <ngx-paypal #paypalBtnLimits1 [config]="payPalConfigLimits1"></ngx-paypal>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingTwo">
                    <h5 class="mb-0">
                      <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo"
                        aria-expanded="false" aria-controls="collapseTwo">
                        +5 Repeating Events (5 EUR)
                      </button>
                    </h5>
                  </div>
                  <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div class="card-body">
                      <ngx-paypal #paypalBtnLimits2 [config]="payPalConfigLimits2"></ngx-paypal>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingThree">
                    <h5 class="mb-0">
                      <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree"
                        aria-expanded="false" aria-controls="collapseThree">
                        +1 Signup Template (5 EUR)
                      </button>
                    </h5>
                  </div>
                  <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                    <div class="card-body">
                      <ngx-paypal #paypalBtnLimits3 [config]="payPalConfigLimits3"></ngx-paypal>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingThree">
                    <h5 class="mb-0">
                      <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour"
                        aria-expanded="false" aria-controls="collapseFour">
                        +2 Event Channels, +2 Repeating Events, +2 Signup Templates (10 EUR)
                      </button>
                    </h5>
                  </div>
                  <div id="collapseFour" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                    <div class="card-body">
                      <ngx-paypal #paypalBtnLimits4 [config]="payPalConfigLimits4"></ngx-paypal>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingThree">
                    <h5 class="mb-0">
                      <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive"
                        aria-expanded="false" aria-controls="collapseFive">
                        +4 Event Channels, +4 Repeating Events, +3 Signup Templates (15 EUR)
                      </button>
                    </h5>
                  </div>
                  <div id="collapseFive" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                    <div class="card-body">
                      <ngx-paypal #paypalBtnLimits5 [config]="payPalConfigLimits5"></ngx-paypal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div><i>NOTE: If you don't see the PayPal buttons, please reload the page (<code>F5</code>). I am working on a fix.</i></div>
      </div>
    </div>
  </div>
</ng-container>
