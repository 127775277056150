import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { shareReplay } from 'rxjs/operators';
import { EmbedBuilderComponent } from 'src/app/components/embed-builder/embed-builder.component';
import { DiscordEmbed, } from 'src/app/components/embed-builder/embed.model';
import { BotGuild, ChannelPreview, CustomEmoji, EventTemplate, SignupTemplate } from 'src/app/models/bot/guild';
import { BotResourceService } from 'src/app/services/bot-resource.service';
import { EmbedService } from 'src/app/services/embed.service';
import { EmojiComponentService } from 'src/app/services/emojiComponent.service';
import { GuildService } from 'src/app/services/guild.service';

interface Event {
  title: string;
  description: string;
  text: string;
  start: Date;
  end: Date;
  footerText: string;
  footerIcon: string;
  embedImage: string;
  embedThumbnail: string;
  templateHash: string;
}

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.css'],
})
export class EventCardComponent
  extends EmbedBuilderComponent
  implements OnInit
{
  guildId: string;
  guild: BotGuild | undefined;
  eventTemplates: EventTemplate[];
  signupTemplates: SignupTemplate[];
  channelSignupTemplateMapping: Map<string, SignupTemplate>;
  allowedChannels: ChannelPreview[];
  serverCustomEmojis$: Observable<CustomEmoji[]>;
  serverCustomEmojis: CustomEmoji[];
  eventChannel: string;
  eventForm: FormGroup;
  startPicker: any[];
  currEmbed: Partial<DiscordEmbed> = {};
  creationInProgress = false;

  dateControl: FormControl;
  startMinDate: Date;
  endMinDate: Date;
  maxDate: Date;

  constructor(
    embedService: EmbedService,
    private route: ActivatedRoute,
    private guildService: GuildService,
    private botService: BotResourceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private emojiComponentService: EmojiComponentService,
  ) {
    super(embedService);
  }

  ngOnInit() {
    this.startMinDate = new Date();
    this.endMinDate = new Date();
    this.maxDate = new Date(
      this.startMinDate.getFullYear() + 1,
      this.startMinDate.getMonth(),
      this.startMinDate.getDate()
    );

    this.eventForm = this.fb.group({
      eventChannel: ['', [Validators.required, Validators.minLength(1)]],
      eventTemplate: [''],
      title: ['', [Validators.required, Validators.maxLength(60)]],
      description: ['', [Validators.maxLength(700)]],
      text: ['', [Validators.maxLength(2000)]],
      start: [this.formatDate(new Date()), Validators.required],
      end: [''],
      footerText: ['', [Validators.maxLength(1900)]],
      footerIcon: [''],
      embedImage: [''],
      embedThumbnail: [''],
      signupTemplate: ['', Validators.required],
    });
    this.guildId = this.route.parent.snapshot.paramMap.get('id');

    const emojis$ = this.botService
      .getGuildEmoji(this.guildId)
      .pipe(shareReplay(1));
    const guildData$ = this.guildService.getGuildData(this.guildId);
    const eventCreateInfos$ = this.botService.getEventCreateInfos(this.guildId);

    forkJoin([emojis$, guildData$, eventCreateInfos$]).subscribe(
      ([emojis, guild, infos]) => {
        // Process emojis
        emojis.forEach((emoji) => {
          emoji.shortNames = [emoji.name, emoji.full_id];
        });
        this.serverCustomEmojis = emojis;

        // Process guild data
        this.guild = guild;
        if (!this.guild.has_premium) {
          this.eventForm.get('embedImage').disable();
          this.eventForm.get('embedThumbnail').disable();
          this.eventForm.get('footerIcon').disable();
          this.eventForm.get('footerText').disable();
        }

        // Process event creation infos
        this.eventTemplates = infos.event_templates;
        this.signupTemplates = infos.signup_templates;
        this.channelSignupTemplateMapping = infos.channel_template_mapping;
        this.allowedChannels = infos.channels_allowed;
        this.eventForm
          .get('eventChannel')
          .setValue(this.allowedChannels[0]?.id || '');
        this.eventForm
          .get('signupTemplate')
          .setValue(
            this.channelSignupTemplateMapping[
              this.eventForm.get('eventChannel').value
            ]
          );

        // Init form
        this.initForm();
      }
    );
  }

  initForm(): void {
    this.eventForm.get('start').valueChanges.subscribe((startDate) => {
      this.endMinDate = new Date(startDate);
      if (
        this.eventForm.get('end').value &&
        this.eventForm.get('end').value < this.endMinDate
      ) {
        this.eventForm.get('end').setValue(this.formatDate(this.endMinDate));
      }
    });

    this.eventForm
      .get('eventChannel')
      .valueChanges.subscribe((eventChannel) => {
        this.eventForm
          .get('signupTemplate')
          .setValue(
            this.channelSignupTemplateMapping[
              this.eventForm.get('eventChannel').value
            ]
          );
      });

    this.eventForm
      .get('eventTemplate')
      .valueChanges.subscribe((eventTemplateIdentifier) => {
        // get the event template from the identifer and set the values on event template change
        const eventTemplate = this.eventTemplates.find(
          (et) => et.e_identifier === eventTemplateIdentifier
        );
        if (!eventTemplate) {
          return;
        }
        if (
          typeof eventTemplate.title === 'string' &&
          eventTemplate.title.length > 0
        ) {
          this.eventForm.get('title').setValue(eventTemplate.title);
        }
        if (
          typeof eventTemplate.description === 'string' &&
          eventTemplate.description.length > 0
        ) {
          this.eventForm.get('description').setValue(eventTemplate.description);
        }
        if (
          typeof eventTemplate.text === 'string' &&
          eventTemplate.text.length > 0
        ) {
          this.eventForm.get('text').setValue(eventTemplate.text);
        }
        if (
          typeof eventTemplate.footer_txt === 'string' &&
          eventTemplate.footer_txt.length > 0
        ) {
          this.eventForm.get('footerText').setValue(eventTemplate.footer_txt);
        }
        if (
          typeof eventTemplate.footer_icon === 'string' &&
          eventTemplate.footer_icon.length > 0
        ) {
          this.eventForm.get('footerIcon').setValue(eventTemplate.footer_icon);
        }
        if (
          typeof eventTemplate.embed_image === 'string' &&
          eventTemplate.embed_image.length > 0
        ) {
          this.eventForm.get('embedImage').setValue(eventTemplate.embed_image);
        }
        if (
          typeof eventTemplate.embed_thumbnail === 'string' &&
          eventTemplate.embed_thumbnail.length > 0
        ) {
          this.eventForm
            .get('embedThumbnail')
            .setValue(eventTemplate.embed_thumbnail);
        }
        if (
          typeof eventTemplate.t_identifier === 'string' &&
          eventTemplate.t_identifier.length > 0
        ) {
          this.eventForm
            .get('signupTemplate')
            .setValue(eventTemplate.t_identifier);
        }

        const startTime = new Date(eventTemplate.start_time);
        if (eventTemplate.start_time && startTime instanceof Date) {
          this.eventForm.get('start').setValue(this.formatDate(startTime));
        }
        const endTime = new Date(eventTemplate.end_time);
        if (eventTemplate.end_time && endTime instanceof Date) {
          this.eventForm.get('end').setValue(this.formatDate(endTime));
        }
      });

    this.eventForm
      .get('signupTemplate')
      .valueChanges.subscribe((signupTemplateIdentifier) => {
        // signupTemplate changes update the event embed emojis
        const signupTemplate = this.getSignupTemplateFromEventForm();
        if (!signupTemplate) {
          this.toastr.error(
            'This event template uses an invalid signup template!',
            'Invalid Signup Template'
          );
          return;
        }
        this.signupTemplateEmojisToEmbedFields(signupTemplate);
      });

    this.eventForm.valueChanges.subscribe(() => {
      // All changes update the event embed
      this.eventFormToEmbed();
      this.embedService.updateEmbed(this.currEmbed);
    });

    // Do initial setup of embed
    this.eventFormToEmbed();
    this.signupTemplateEmojisToEmbedFields(
      this.getSignupTemplateFromEventForm()
    );
    this.embedService.updateEmbed(this.currEmbed);
  }

  getSignupTemplateFromEventForm() {
    return this.signupTemplates.find(
      (st) => st.identifier === this.eventForm.get('signupTemplate').value
    );
  }

  eventFormToEmbed(): void {
    const startDateTime = this.formatDateTime(
      this.eventForm.get('start').value
    );
    const endTime = this.eventForm.get('end').value
      ? this.formatDateTime(this.eventForm.get('end').value).time
      : 'None';

    this.currEmbed.title = this.eventForm.get('title').value;
    this.currEmbed.description =
      '**Event Info:**\n' +
      '🗓️ ' +
      startDateTime.date +
      '\n' +
      '🕑 ' +
      startDateTime.time +
      ' - ' +
      endTime +
      '\n' +
      '**Description:**  \n' +
      this.eventForm.get('description').value;
    this.currEmbed.text = this.eventForm.get('text').value;
    this.currEmbed.footerText = this.eventForm.get('footerText').value;
    this.currEmbed.footerIconUrl = this.eventForm.get('footerIcon').value;
    this.currEmbed.url = this.eventForm.get('embedImage').value;
    this.currEmbed.thumbnailUrl = this.eventForm.get('embedThumbnail').value;
  }

  formatDateTime(datetime: string): { date: string; time: string } {
    const dateObj = new Date(datetime);
    const date = dateObj.toLocaleDateString();
    const time = dateObj.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
    return { date, time };
  }

  formatDate(date: Date): string {
    const d = new Date(date);
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    const hours = ('0' + d.getHours()).slice(-2);
    const minutes = ('0' + d.getMinutes()).slice(-2);
    return `${d.getFullYear()}-${month}-${day}T${hours}:${minutes}`;
  }

  onSubmit(): void {
    if (this.eventForm.valid) {
      this.creationInProgress = true;
      if (this.eventForm.get('end').value === null) {
        // If the user adds an end date and than removes it again,
        // the end date is set to null for some reason. Backend needs an empty
        // string, so we set it back to empty.
        this.eventForm.get('end').setValue('');
      }
      const event: Event = this.eventForm.value;
      this.botService.createEvent(this.guildId, event).subscribe({
        next: (response: any) => {
          this.creationInProgress = false;
          if (response.status === 201) {
            this.eventForm.get('title').setValue('');
            this.eventForm.get('description').setValue('');
            this.eventForm.get('text').setValue('');
            this.eventForm.get('footerText').setValue('');
            this.eventForm.get('footerIcon').setValue('');
            this.eventForm.get('embedImage').setValue('');
            this.eventForm.get('embedThumbnail').setValue('');
            this.eventForm.get('start').setValue('');
            this.eventForm.get('end').setValue('');
            this.eventForm
              .get('signupTemplate')
              .setValue(
                this.channelSignupTemplateMapping[
                  this.eventForm.get('eventChannel').value
                ]
              );
            this.eventForm.get('eventTemplate').setValue('');
          }
        },
        error: (error: any) => {
          console.error('Error creating event:', error);
          this.creationInProgress = false;
        },
      });
    }
  }

  private convertEmojisObjectToArray(emojisObject: Record<string, any>): any[] {
    return Object.keys(emojisObject).map(mainEmojiKey => {
      const mainEmoji = emojisObject[mainEmojiKey];
      const subEmojis = mainEmoji.emojis
        ? Object.keys(mainEmoji.emojis).map(subEmojiKey => ({
            emoji: subEmojiKey,
            fieldName: mainEmoji.emojis[subEmojiKey].field_name,
            limit: 0 // Default limit, adjust if needed
          }))
        : [];

      return {
        emoji: mainEmojiKey,
        fieldName: mainEmoji.field_name,
        type: mainEmoji.type,
        subEmojis: subEmojis,
        limit: 0 // Default limit, adjust if needed
      };
    });
  }

  private signupTemplateEmojisToEmbedFields(signupTemplate): void {
    this.emojiComponentService.signupTemplateEmojisToEmbedFields(
      // signupTemplate,
      this.embedService,
      this.serverCustomEmojis,
      { currEmbed: this.currEmbed },
      {
        emojis: this.convertEmojisObjectToArray(signupTemplate.emojis),
        emojiBehavior: signupTemplate.emoji_behavior,
        useButtons: signupTemplate.use_buttons,
        color: signupTemplate.color,
        emojiTitle: signupTemplate.emoji_title,
      }
    );
  }
}
