import { Pipe, PipeTransform } from '@angular/core';
import { EventRepeating } from '../models/bot/guild';

type ExtendedEventRepeating = EventRepeating & { index: number };

@Pipe({
  name: 'findEvent'
})
export class FindEventPipe implements PipeTransform {

  transform(values: EventRepeating[], channelId: string): ExtendedEventRepeating[] {
    const extended: ExtendedEventRepeating[] =
      values.map((value, i) => ({ ...value, index: i})).filter(value => value.channel_id === channelId);
    return extended.length > 0 ? extended : undefined;
  }

}
