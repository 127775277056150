import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserInfo } from 'angular-oauth2-oidc';
import { PayPalScriptService, IPayPalConfig, NgxPaypalComponent, ICreateOrderRequest } from 'ngx-paypal';
import { ToastrService } from 'ngx-toastr';
import { BotGuild } from 'src/app/models/bot/guild';
import { GuildService } from 'src/app/services/guild.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-paypal-card',
  templateUrl: './paypal-card.component.html',
  styleUrls: ['./paypal-card.component.css']
})
export class PaypalCardComponent implements OnInit, AfterViewInit {
  guildId: string;
  guild: BotGuild;
  private userInfo: UserInfo;

  public payPalConfigPremium1Month?: IPayPalConfig;
  public payPalConfigPremium6Month?: IPayPalConfig;
  public payPalConfigPremium12Month?: IPayPalConfig;
  public payPalConfigPremiumLifetime?: IPayPalConfig;
  public payPalConfigLimits1?: IPayPalConfig;
  public payPalConfigLimits2?: IPayPalConfig;
  public payPalConfigLimits3?: IPayPalConfig;
  public payPalConfigLimits4?: IPayPalConfig;
  public payPalConfigLimits5?: IPayPalConfig;

  @ViewChild('paypalBtnPremium1Month') paypalBtnPremium1Month?: NgxPaypalComponent;
  @ViewChild('paypalBtnPremium6Month') paypalBtnPremium6Month?: NgxPaypalComponent;
  @ViewChild('paypalBtnPremium12Month') paypalBtnPremium12Month?: NgxPaypalComponent;
  @ViewChild('paypalBtnPremiumLifetime') paypalBtnPremiumLifetime?: NgxPaypalComponent;
  @ViewChild('paypalBtnLimits1') paypalBtnLimits1?: NgxPaypalComponent;
  @ViewChild('paypalBtnLimits2') paypalBtnLimits2?: NgxPaypalComponent;
  @ViewChild('paypalBtnLimits3') paypalBtnLimits3?: NgxPaypalComponent;
  @ViewChild('paypalBtnLimits4') paypalBtnLimits4?: NgxPaypalComponent;
  @ViewChild('paypalBtnLimits5') paypalBtnLimits5?: NgxPaypalComponent;


  constructor(
    private route: ActivatedRoute,
    private guildService: GuildService,
    private toastr: ToastrService,
    private payPalScriptService: PayPalScriptService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.guildId = this.route.parent.snapshot.paramMap.get('id');
    this.userInfo = this.userService.getUserInfo();
    this.guildService.getGuildData(this.guildId).subscribe(guild => {
      this.guild = guild;
    });
  }

  ngAfterViewInit(): void {
    this.init_paypal();
  }

  private paypal_success() {
    this.toastr.success("Please give PayPal up to 10 minutes to process the Payment", "Success!", { timeOut: 4000 })
  }

  private init_paypal() {
    this.payPalConfigPremium1Month = {
      currency: environment.paypalCurrency,
      clientId: environment.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'EUR',
              value: '3.50'
            },
            custom_id: this.userInfo.info.id.toString() + "-" + this.guildId + "-" + "NN2q5pBLGe",
          },
        ],
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        this.paypal_success();
      },
      onError: err => {
        console.log('OnError', err);
      },
    };

    this.payPalConfigPremium6Month = {
      currency: environment.paypalCurrency,
      clientId: environment.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'EUR',
              value: '20.00'
            },
            custom_id: this.userInfo.info.id.toString() + "-" + this.guildId + "-" + "8LFEkg83Dv",
          },
        ],
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        this.paypal_success();
      },
      onError: err => {
        console.log('OnError', err);
      },
    };

    this.payPalConfigPremium12Month = {
      currency: environment.paypalCurrency,
      clientId: environment.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'EUR',
              value: '38.00'
            },
            custom_id: this.userInfo.info.id.toString() + "-" + this.guildId + "-" + "VDg5iwHiye",
          },
        ],
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        this.paypal_success();
      },
      onError: err => {
        console.log('OnError', err);
      },
    };

    this.payPalConfigPremiumLifetime = {
      currency: environment.paypalCurrency,
      clientId: environment.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'EUR',
              value: '80.00'
            },
            custom_id: this.userInfo.info.id.toString() + "-" + this.guildId + "-" + "CsBakoESk9",
          },
        ],
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        this.paypal_success();
      },
      onError: err => {
        console.log('OnError', err);
      },
    };

    this.payPalConfigLimits1 = {
      currency: environment.paypalCurrency,
      clientId: environment.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'EUR',
              value: '5.00'
            },
            custom_id: this.userInfo.info.id.toString() + "-" + this.guildId + "-" + "6j947ttTgS",
          },
        ],
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        this.paypal_success();
      },
      onError: err => {
        console.log('OnError', err);
      },
    };

    this.payPalConfigLimits2 = {
      currency: environment.paypalCurrency,
      clientId: environment.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'EUR',
              value: '5.00'
            },
            custom_id: this.userInfo.info.id.toString() + "-" + this.guildId + "-" + "TJ43J6Yy2H",
          },
        ],
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        this.paypal_success();
      },
      onError: err => {
        console.log('OnError', err);
      },
    };

    this.payPalConfigLimits3 = {
      currency: environment.paypalCurrency,
      clientId: environment.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'EUR',
              value: '5.00'
            },
            custom_id: this.userInfo.info.id.toString() + "-" + this.guildId + "-" + "9jMdy7LuHo",
          },
        ],
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        this.paypal_success();
      },
      onError: err => {
        console.log('OnError', err);
      },
    };

    this.payPalConfigLimits4 = {
      currency: environment.paypalCurrency,
      clientId: environment.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'EUR',
              value: '10.00'
            },
            custom_id: this.userInfo.info.id.toString() + "-" + this.guildId + "-" + "7NinkYY9f5",
          },
        ],
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        this.paypal_success();
      },
      onError: err => {
        console.log('OnError', err);
      },
    };

    this.payPalConfigLimits5 = {
      currency: environment.paypalCurrency,
      clientId: environment.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'EUR',
              value: '15.00'
            },
            custom_id: this.userInfo.info.id.toString() + "-" + this.guildId + "-" + "SdxzQ6f2iY",
          },
        ],
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        this.paypal_success();
      },
      onError: err => {
        console.log('OnError', err);
      },
    };

    this.payPalScriptService.registerPayPalScript({
      currency: environment.paypalCurrency,
      clientId: environment.paypalClientId,
    }, (payPalApi) => {
      if (this.paypalBtnPremium1Month) {
        this.paypalBtnPremium1Month.customInit(payPalApi);
      }
      if (this.paypalBtnPremium6Month) {
        this.paypalBtnPremium6Month.customInit(payPalApi);
      }
      if (this.paypalBtnPremium12Month) {
        this.paypalBtnPremium12Month.customInit(payPalApi);
      }
      if (this.paypalBtnPremiumLifetime) {
        this.paypalBtnPremiumLifetime.customInit(payPalApi);
      }
      if (this.paypalBtnLimits1) {
        this.paypalBtnLimits1.customInit(payPalApi);
      }
      if (this.paypalBtnLimits2) {
        this.paypalBtnLimits2.customInit(payPalApi);
      }
      if (this.paypalBtnLimits3) {
        this.paypalBtnLimits3.customInit(payPalApi);
      }
      if (this.paypalBtnLimits4) {
        this.paypalBtnLimits4.customInit(payPalApi);
      }
      if (this.paypalBtnLimits5) {
        this.paypalBtnLimits5.customInit(payPalApi);
      }

    });
  }

}
