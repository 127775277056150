<div class="embed-builder" *ngIf="signupTemplate">
  <mat-accordion multi>
    <mat-expansion-panel expanded class="expansion-pannel-general">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h5>General</h5>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="emojiForm" class="form-container">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Template Title </mat-label>
          <input matInput formControlName="signupTemplateTitle" />
          <mat-error *ngIf="emojiForm.get('signupTemplateTitle').errors?.required">This field is required</mat-error>
          <mat-error *ngIf="emojiForm.get('signupTemplateTitle').errors?.minlength">Title is too short (min.
            1)</mat-error>
          <mat-error *ngIf="emojiForm.get('signupTemplateTitle').errors?.maxlength">Title is too long (max.
            25)</mat-error>
        </mat-form-field>

        <div class="emoji-row">
          <mat-label class="pr-3">Template Icon</mat-label>
          <app-emoji-picker-button class="emoji-picker-component" [customEmojis]="serverCustomEmojis"
            [defaultEmoji]="emojiForm.get('signupTemplateIcon').value"
            (emojiSelected)="emojiForm.get('signupTemplateIcon').setValue($event)">
          </app-emoji-picker-button>
        </div>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Event Embed Color </mat-label>
          <input matInput type="color" formControlName="signupTemplateColor" />
          <mat-error *ngIf="emojiForm.get('signupTemplateColor').errors?.required">
            Color is required.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Emoji Behavior </mat-label>
          <mat-select formControlName="signupTemplateEmojiBehavior">
            <mat-option [value]="'1'">All emojis on top level (max 20 emojis)</mat-option>
            <mat-option [value]="'2'">Signup to sub emojis via emoji (max 20x20 emojis)</mat-option>
            <mat-option [value]="'3'">Signup to sub emojis via number (max 20x<code>infinity</code> emojis)</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="signuptemplateEmojiTitle mb-2">
          <mat-slide-toggle class="toggle-label" formControlName="signupTemplateEmojiTitle"
            [checked]="signupTemplateEmojiTitle">
            Display top level emojis next to field title
          </mat-slide-toggle>
        </div>
        <div class="signupTemplateUseButtons">
          <mat-slide-toggle class="toggle-label" formControlName="signupTemplateUseButtons"
            [checked]="signupTemplateUseButtons">
            Use Buttons instead of Emojis
        <small class="form-text text-muted"><span role="img" aria-label="danger">⚠️</span> Deprecated! Emojis might be disabled soon!</small>
      </mat-slide-toggle>
        </div>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel expanded class="expansion-pannel-emojis">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h5>Emojis</h5>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="emojiForm">
        <div formArrayName="emojis" cdkDropList (cdkDropListDropped)="drop($event)">
          <div *ngFor="let emojiCtrl of emojis.controls; let i = index" [formGroupName]="i" cdkDrag>
            <div class="emoji-row">
              <fa-icon *ngIf="isDesktop" [icon]="faIcons.bars" class="drag-handle mr-2 ml-1" cdkDragHandle></fa-icon>

              <mat-form-field class="mb-0">
                <mat-label>Type</mat-label>
                <mat-select formControlName="type" panelClass="emoji-type-select">
                  <mat-option value="role">Role</mat-option>
                  <mat-option value="status">Status</mat-option>
                  <mat-option [value]="'remove'" [disabled]="isTypeSelected('remove')">Sign Off</mat-option>
                  <mat-option [value]="'late'" [disabled]="isTypeSelected('late')">Late (+5 min)</mat-option>
                  <mat-option [value]="'rlate'" [disabled]="isTypeSelected('rlate')">Remove Late Status</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field *ngIf="emojiCtrl.value.type === 'role' || emojiCtrl.value.type === 'status'">
                <mat-label>Description</mat-label>
                <input matInput formControlName="fieldName" required>
              </mat-form-field>

              <app-emoji-picker-button class="emoji-picker-component" [customEmojis]="serverCustomEmojis"
                [defaultEmoji]="emojiCtrl.value.emoji" [resetSignal]="resetSignal"
                (emojiSelected)="handleEmojiSelection(emojiCtrl.get('emoji'), $event, i, false)">
              </app-emoji-picker-button>

              <mat-error *ngIf="emojiCtrl.get('emoji').hasError('duplicate')" class="pl-1 warning-emoji"
                matTooltip="Duplicate emoji">⚠️</mat-error>
              <mat-error *ngIf="emojiCtrl.get('emoji').hasError('emojiLimit')" class="pl-1 warning-emoji"
                matTooltip="Emoji limit exceeded">⚠️</mat-error>

              <button mat-icon-button color="warn" (click)="removeEmoji(i)">
                <fa-icon [icon]="faIcons.trash"></fa-icon>
              </button>
            </div>

            <div formArrayName="subEmojis" cdkDropList (cdkDropListDropped)="dropSubEmoji($event, i)">
              <div *ngFor="let subEmojiCtrl of emojiCtrl.get('subEmojis').controls; let j = index" [formGroupName]="j"
                class="sub-emoji-row" cdkDrag>
                <fa-icon *ngIf="isDesktop" [icon]="faIcons.bars" class="drag-handle mr-2 ml-1" cdkDragHandle></fa-icon>
                <mat-form-field>
                  <mat-label>Sub Emoji Description</mat-label>
                  <input matInput formControlName="fieldName" required>
                </mat-form-field>

                <app-emoji-picker-button class="emoji-picker-component" [customEmojis]="serverCustomEmojis"
                  [defaultEmoji]="subEmojiCtrl.value.emoji" [resetSignal]="resetSignal"
                  (emojiSelected)="handleEmojiSelection(subEmojiCtrl.get('emoji'), $event, i, true)">
                </app-emoji-picker-button>

                <mat-error *ngIf="subEmojiCtrl.get('emoji').hasError('duplicate')" class="pl-1 warning-emoji"
                  matTooltip="Duplicate emoji">⚠️</mat-error>
                <mat-error *ngIf="subEmojiCtrl.get('emoji').hasError('emojiLimit')" class="pl-1 warning-emoji"
                  matTooltip="Emoji limit exceeded">⚠️</mat-error>
                <mat-error *ngIf="subEmojiCtrl.get('emoji').hasError('subEmojiLimit')" class="pl-1 warning-emoji"
                  matTooltip="Sub-Emoji limit exceeded">⚠️</mat-error>

                <button mat-icon-button color="warn" (click)="removeSubEmoji(i, j)">
                  <fa-icon [icon]="faIcons.trash"></fa-icon>
                </button>
              </div>
              <button *ngIf="emojiCtrl.value.type === 'role' || emojiCtrl.value.type === 'status'" class="sub-emoji-row"
                mat-raised-button color="primary" (click)="addSubEmoji(i)">Add Sub Emoji</button>
            </div>
          </div>
        </div>

        <div class="emoji-button" matTooltip="A maximum of 20 emojis is allowed"
          [matTooltipDisabled]="emojis.length < 20" matTooltipPosition="below">
          <button mat-raised-button color="accent" (click)="addEmoji()" [disabled]="emojis.length >= 20">Add Top Level
            Emoji</button>
        </div>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h5>Limits</h5>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>Apply sign up limits to fields.</p>
      <ul>
        <li><code>0</code> means that no limits apply to that field.</li>
        <!-- TODO: Fix link -->
        <li>Limits higher up in the <a href="https://docs.raidorganizer.org/signup-templates/create-template/#hierarchy"
            target="_blank">hirarchy</a> will be applied first.</li>
      </ul>
      <h5>General Limits</h5>
      <form [formGroup]="emojiForm">
        <div formGroupName="generalLimits">
          <div *ngFor="let limit of generalSignupLimits">
            <mat-form-field class="full-width">
              <mat-label>{{ limit.longName }}</mat-label>
              <input type="number" matInput [formControlName]="limit.key" min="0">
              <mat-error *ngIf="emojiForm.get('generalLimits').get(limit.key).invalid">
                Please enter a valid number
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>

      <h5>Field Limits</h5>
      <form [formGroup]="emojiForm">
        <div formArrayName="emojis">
          <div *ngFor="let emojiCtrl of emojis.controls; let i = index" [formGroupName]="i">
            <div *ngIf="emojiCtrl.get('type').value === 'role' || emojiCtrl.get('type').value === 'status'">
              <div class="emoji-limits-row">
                <mat-form-field class="">
                  <mat-label for="mainEmojiLimit">{{ emojiCtrl.get('fieldName').value }}</mat-label>
                  <div class="emoji-limit-input">
                    <input class="form-control" type="number" matInput formControlName="limit" min="0">
                    <mat-error *ngIf="emojiCtrl.get('limit').invalid">
                      Please enter a valid number
                    </mat-error>
                  </div>
                </mat-form-field>
              </div>

              <div formArrayName="subEmojis">
                <div *ngFor="let subEmojiCtrl of emojiCtrl.get('subEmojis').controls; let j = index"
                  [formGroupName]="j">
                  <div class="emoji-limits-row sub-emoji">
                    <mat-form-field class="">
                      <mat-label for="subemojiLimit">{{ emojiCtrl.get('fieldName').value }}, {{
                        subEmojiCtrl.get('fieldName').value }}</mat-label>
                      <div class="emoji-limit-input">
                        <input class="form-control" type="number" matInput formControlName="limit" min="0">
                        <mat-error *ngIf="subEmojiCtrl.get('limit').invalid">
                          Please enter a valid number
                        </mat-error>
                      </div>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- <div class="col-12 col-md-2 pl-0 pr-0">
  <button mat-button class="" mat-raised-button color="primary" [disabled]="!isFormDirty" (click)="saveTemplate()">Update Template</button>
</div> -->
  <div class="row ml-0 mt-3">
    <button mat-button class="update-button" mat-raised-button color="primary"
      [disabled]="!isFormDirty || !emojiForm.valid" (click)="saveTemplate()">
      {{ signupTemplateId === null ? 'Create Template' : 'Update Template' }}
    </button>
    <button *ngIf="isFormDirty && signupTemplateId !== null && isBreakingChange" class="danger-button"
      (click)="openEditSignupTempalteDangerDialog()">
      <span role="img" aria-label="danger">⚠️</span>
    </button>
  </div>
  <div class="row ml-0 mt-3" *ngIf="signupTemplateId !== null">
    <button mat-button class="update-button" mat-raised-button color="primary" (click)="deleteTemplate()"><fa-icon
        [icon]="faIcons.trash" style="font-size: 12px"></fa-icon> Delete Template
    </button>
  </div>
</div>

<ng-template #editSignupTemplateDangerDialog>
  <h1 mat-dialog-title>Warning</h1>
  <div mat-dialog-content>
    <p>You have edited either the <code>Emoji Behavior</code> or an <code>Emoji</code>!</p>
    <p>Changing one of those will break all existing entries that use this template.
      The entries affected are:
    </p>
    <ng-container *ngIf="signupTemplate?.used_by; else noEntries">
      <div *ngIf="getEventEntries().length > 0">
        <h2>Events</h2>
        <ul>
          <li *ngFor="let event of getEventEntries()">
            <a href="https://discord.com/channels/{{ guildId }}/{{ event.channel_id }}/{{ event.message_id }}"
              target="_blank">
              {{ event.title }}
            </a>
          </li>
        </ul>
      </div>
      <div *ngIf="getRepeatingEventEntries().length > 0">
        <h2>Repeating Events</h2>
        <ul>
          <li *ngFor="let repeatingEvent of getRepeatingEventEntries()">
            <code>{{ repeatingEvent.id }}</code>{{ repeatingEvent.title }}
          </li>
        </ul>
      </div>
      <div *ngIf="getEventTemplateEntries().length > 0">
        <h2>Event Templates</h2>
        <ul>
          <li *ngFor="let eventTemplate of getEventTemplateEntries()">
            <code>{{ eventTemplate.id }}</code>{{ eventTemplate.title }}
          </li>
        </ul>
      </div>
    </ng-container>
    <ng-template #noEntries>
      <h2>None</h2>
    </ng-template>
  </div>
  <div mat-dialog-actions class="dialog-actions" *ngIf="showConfirmButtons">
    <button mat-button (click)="dialogEditTemplateRef.close(false)">Cancel</button>
    <button mat-button color="warn" (click)="confirmEditAction()">Confirm</button>
  </div>
</ng-template>

<ng-template #deleteSignupTemplateDangerDialog>
  <h1 mat-dialog-title>Warning</h1>
  <div mat-dialog-content>
    <p>You are about to delete this template.</p>
    <p>Deleting this template will break all existing entries that use this template.
      The entries affected are:
    </p>
    <ng-container>
      <div *ngIf="getEventEntries().length > 0">
        <h2>Events</h2>
        <ul>
          <li *ngFor="let event of getEventEntries()">
            <a href="https://discord.com/channels/{{ guildId }}/{{ event.channel_id }}/{{ event.message_id }}"
              target="_blank">
              {{ event.title }}
            </a>
          </li>
        </ul>
      </div>
      <div *ngIf="getRepeatingEventEntries().length > 0">
        <h2>Repeating Events</h2>
        <ul>
          <li *ngFor="let repeatingEvent of getRepeatingEventEntries()">
            <code>{{ repeatingEvent.id }}</code>{{ repeatingEvent.title }}
          </li>
        </ul>
      </div>
      <div *ngIf="getEventTemplateEntries().length > 0">
        <h2>Event Templates</h2>
        <ul>
          <li *ngFor="let eventTemplate of getEventTemplateEntries()">
            <code>{{ eventTemplate.id }}</code>{{ eventTemplate.title }}
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button mat-button (click)="dialogDeleteTemplateRef.close(false)">Cancel</button>
    <button mat-button color="warn" (click)="confirmDeleteAction()">Confirm</button>
  </div>
</ng-template>
