<div class="discord-text" markdown [data]="embed.text"></div>
<div class="discord-embed" [ngStyle]="{'border-left': '4px solid ' + (embed.color || '#7289da')}">
  <div class="embed-header">
    <img [src]="embed.authorIconUrl" class="author-icon" *ngIf="embed.authorIconUrl">
    <span class="author-name">{{ embed.authorName }}</span>
  </div>
  <div class="embed-content">
    <div class="embed-title">{{ embed.title }}</div>
    <div class="embed-description" markdown [data]="embed.description"></div>
    <img [src]="embed.thumbnailUrl" class="embed-thumbnail" *ngIf="embed.thumbnailUrl">
    <div class="embed-fields">
      <div *ngFor="let field of embed.fields" [ngClass]="{'inline-field': field.inline, 'block-field': !field.inline}">
        <div class="embed-field-name" markdown [data]="field.name"></div>
        <div class="embed-field-value" markdown [data]="field.value"></div>
      </div>
    </div>
    <img [src]="embed.url" class="embed-image" *ngIf="embed.url">
    <div class="embed-footer">
      <img [src]="embed.footerIconUrl" class="footer-icon" *ngIf="embed.footerIconUrl">
      <span class="footer-text">{{ embed.footerText }}</span>
    </div>
  </div>
</div>
<div class="emoji-list" *ngIf="!signupTemplateUseButtons">
  <ng-container *ngFor="let emoji of emojis">
    <span class="emoji-item">
      <ng-container *ngIf="isValidUrl(emoji.url); else unicodeEmoji">
        <img [src]="sanitizeUrl(emoji.url)" alt="{{emoji.name}}" class="emoji-icon emoji-icon-custom">
      </ng-container>
      <ng-template #unicodeEmoji>
        <span class="emoji-icon emoji-icon-unicode">{{emoji.url}}</span>
      </ng-template>
      <span class="emoji-count">{{emoji.count}}</span>
    </span>
  </ng-container>
</div>

<div class="emoji-container" *ngIf="signupTemplateUseButtons && emojis">
  <!-- Role and Remove Buttons -->
  <div class="button-row pt-1" *ngFor="let row of chunkedEmojis">
    <ng-container *ngFor="let emoji of row">
      <button *ngIf="emoji.type === 'role' || emoji.type === 'remove'" mat-raised-button
        [class.red]="emoji.type === 'remove'" class="emoji-button">
        <ng-container *ngIf="isValidUrl(emoji.url); else unicodeEmojiButton">
          <img [src]="sanitizeUrl(emoji.url)" alt="{{emoji.name}}" class="emoji-icon emoji-icon-custom">
        </ng-container>
        <ng-template #unicodeEmojiButton>
          <span class="emoji-icon emoji-icon-unicode">{{emoji.url}}</span>
        </ng-template>
        {{ emoji.fieldName }}
      </button>
    </ng-container>
  </div>

  <!-- Dropdown for Other Types -->
  <div class="dropdown-row" *ngIf="hasStatusOrSpecialEmojis">
    <mat-form-field appearance="fill" class="status-dropdown">
      <mat-label>Select a status</mat-label>
      <mat-select>
        <ng-container *ngFor="let emoji of emojis">
          <ng-container *ngIf="emoji.type !== 'role' && emoji.type !== 'remove'">
            <mat-option [value]="emoji.name">
              <ng-container *ngIf="isValidUrl(emoji.url); else unicodeEmojiButton">
                <img [src]="sanitizeUrl(emoji.url)" alt="{{emoji.name}}" class="emoji-icon emoji-icon-custom">
              </ng-container>
              <ng-template #unicodeEmojiButton>
                <span class="emoji-icon emoji-icon-unicode">{{emoji.url}}</span>
              </ng-template>
              <span class="emoji-label">{{emoji.fieldName}}</span>
            </mat-option>
          </ng-container>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
</div>
