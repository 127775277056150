import { Injectable } from '@angular/core';
import { CustomEmoji } from '../models/bot/guild';
import { DiscordEmoji, EmbedField } from '../components/embed-builder/embed.model';
import { EmojiParserService } from 'src/app/services/emojiParser.service';
import { EmbedService } from './embed.service';

@Injectable({
  providedIn: 'root',
})
export class EmojiComponentService {

  constructor(
        private emojiParser: EmojiParserService,
  ) {}

  signupTemplateEmojisToEmbedFields(
    embedService: EmbedService,
    serverCustomEmojis: CustomEmoji[],
    embedFields: { currEmbed?: any},
    emojiInfo: { emojis: any, emojiBehavior: string; useButtons: boolean; color: string; emojiTitle: boolean }
  ): void {
    const roleFields: EmbedField[] = [];
    const statusFields: EmbedField[] = [];
    const msgEmojis: DiscordEmoji[] = []; // Holds the emojis displayed at the bottom of the preview
    let hasStatusField = false;
    let hasRoleField = false;

    for (const mainEmoji of emojiInfo.emojis) {
      let value = "";
      const displayEmoji = this.emojiParser?.isCustomEmoji(mainEmoji.emoji)
        ? this.emojiParser.parseCustomEmojiForEmbed(mainEmoji.emoji, serverCustomEmojis)
        : mainEmoji.emoji;

      // Only display top level emojis on 2 and 3 and
      // on 1 if sub emojis are present
      if (!(mainEmoji.subEmojis.length && emojiInfo.emojiBehavior === '1')) {
        msgEmojis.push(
          this.emojiParser.parseEmojiForReaction(
            mainEmoji.emoji,
            serverCustomEmojis,
            mainEmoji.fieldName,
            mainEmoji.type
          )
        );
        value = `${displayEmoji} - ${mainEmoji.fieldName}\n`;
      }

      const subEmojis = mainEmoji.subEmojis;
      if (subEmojis) {
        for (const subEmoji of subEmojis) {
          if (emojiInfo.emojiBehavior === '1') {
            msgEmojis.push(
              this.emojiParser.parseEmojiForReaction(
                subEmoji.emoji,
                serverCustomEmojis,
                `${mainEmoji.fieldName}, ${subEmoji.fieldName}`,
                mainEmoji.type
                )
              );
          }
          value += `${subEmoji.emoji} - ${mainEmoji.fieldName}, ${subEmoji.fieldName}\n`;
        }
      }

      const name = emojiInfo.emojiTitle ? `${displayEmoji} ${mainEmoji.fieldName}` : mainEmoji.fieldName;
      if (mainEmoji.type === 'status') {
        statusFields.push({
          name: name,
          value: value.trim(),
          inline: true
        });
        hasStatusField = true;
      } else if (mainEmoji.type === 'role') {
        roleFields.push({
          name: name,
          value: value.trim(),
          inline: true
        });
        hasRoleField = true;
      }
    }

    const targetEmbedFields = embedFields.currEmbed // || embedFields.signupConfg; TODO: check what this config was.
    targetEmbedFields.fields = roleFields;

    if (hasStatusField) {
      if (hasRoleField) {
        targetEmbedFields.fields.push({
          name: '',
          value: '---',
          inline: false
        });
      }
      targetEmbedFields.fields.push(...statusFields);
    }

    targetEmbedFields.color = emojiInfo?.color ?? "#000000"

    embedService.updateEmbed(targetEmbedFields);
    embedService.updateEmojis(msgEmojis);
    embedService.updateSignupTemplateUseButtons(emojiInfo?.useButtons);
  }
}
